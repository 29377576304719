export default {
    data() {
        return {
            show: false,
            acao: null,
            nameToDelete: null,
            idToDelete: null,
            editUser: {},
            inputPesquisar: '',
        };
    },
    methods: {
        emitParaMudarCP(val) {
            this.$emit("mudaComponente", val);
        },
        adicionarUsuario() {
            this.editUser = {};
            this.acao = "adicionar";
            this.showModal("modalNameNovoEditarUsuario");
        },
        verificaDeletarUsuario(id, name) {
            this.idToDelete = id;
            this.nameToDelete = name;
            this.showModal("modalNameConfirmacao");
        },
        editarUsuario(val) {
            this.acao = "editar";
            this.editUser = val;
            this.showModal("modalNameNovoEditarUsuario");
        },
        deletarUsuario() {
            let objToDelete = {
                typeAjax: "DELETE",
                cod: this.idToDelete,
            };
            this.$store.dispatch("delUser", objToDelete).then((resolve) => {
                if (resolve.valid) {
                    this.toastGlobal(
                        "success",
                        `O usuário ${this.nameToDelete} foi deletado com sucesso`
                    );

                    this.$store.dispatch(
                        "getDadosDeTodosUsuarios",
                        this.$store.getters.getPerfilToken
                    );
                    this.atualizaDados()
                    this.hideModal("modalNameConfirmacao");
                } else {
                    this.toastGlobal(
                        "error",
                        resolve.msg.reject.msg
                    );
                }
            });
        },
    },
}
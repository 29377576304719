<template>
  <div id="Coordenador_Progresso_Turmas" class="pl-1" v-if="validador($route.meta.typePerfilVerificaRouta, getUserDatas.profile_id)">
    <component :is="componentEmQuestao" @mudaComponente="changeCP" /> 
  </div>
</template>

<script>
import theAdministradorInvestidores from "../components/administrador/theAdministradorInvestidores";
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      componentEmQuestao: theAdministradorInvestidores,
    };
  },
  components: {
    theAdministradorInvestidores,
  },
  computed:{
    ...mapGetters(['getUserDatas'])
  },
  methods: {
    changeCP(nomeDoComponente) {
      this.componentEmQuestao = nomeDoComponente;
    },
  },
};
</script>

<style scoped></style>

<template>
  <modal
    id="modalVisualizaDados"
    name="modalNameVisualizaDados"
    :height="'auto'"
    :width="'70%'"
    :draggable="true"
    :adaptive="true"
    @before-open="verifyToOpen()"
    @before-close="verifyToClose()"
  >
    <div
      :class="
        ` py-5 px-5 position-relative ${
          $route.path.match('investidor') ? 'transparent' : bg_color
        }`
      "
    >
      <!-- <div
        id="userId"
        class="position-absolute d-flex j-c-center hoverStyleButton"
        :content="`Ual...Esse é o ID no SKAKI de '${dadosVisualizarUsuario.name}' 😉`"
        v-tippy="{ placement: 'right' }"
      >
        <span id="idSkaki" class="color-fff f-w-700"
          >{{ $route.path.match('meus-dados') ? meusDados.id : dadosVisualizarUsuario.id }}</span
        >
      </div> -->

      <div :class="`px-0 ${container} border-default shadow-sm`">
        <div
          :class="
            `bg-fff chamaFormularioModal position-relative ${
              !shadow_sm ? '' : 'shadow-sm'
            } ${px}`
          "
        >
          <div class="row h-100 ">
            <div class="col-md-8 mb-5">
              <div class="row">
                <div
                  class="col-md-6 text-left"
                  v-if="dadosVisualizarUsuario.id"
                >
                  <label for="" class="mb-2 mt-4">Nome Completo</label> <br />
                  <input
                    type="text"
                    class="font-padrao"
                    v-model="dadosVisualizarUsuario.name"
                    disabled
                  />
                </div>

                <!-- <div class="col-md-6 text-left">
                  <label for="" class="mb-2 mt-4">Primeiro Nome</label> <br />
                  <input
                    type="text"
                    class="font-padrao"
                    placeholder="Primeiro
                  Nome"
                    :value="dadosVisualizarUsuario.name.replace(/ .*/, '')"
                    disabled
                  />
                </div> -->

                <div class="col-md-6 text-left">
                  <label for="" class="mb-2 mt-4">CPF</label> <br />
                  <the-mask
                    type="text"
                    class="font-padrao"
                    placeholder="010.020.030-40"
                    :mask="['###.###.###-##']"
                    :value="dadosVisualizarUsuario.cpf"
                    disabled
                  />
                </div>
                <div
                  class="col-md-6 text-left"
                  v-if="$route.path.match(/(coordenadores|professores|alunos)/)"
                >
                  <label for="" class="mb-2 mt-4">Instituição</label> <br />
                  <input
                    v-if="dadosVisualizarUsuario.unidades"                  
                    type="text"
                    class="font-padrao"
                    v-model="dadosVisualizarUsuario.unidades.name"
                    disabled
                  />
                  <input  
                    v-else                  
                    type="text"
                    class="font-padrao"
                    disabled
                  />
                </div>
                
                <div
                  class="col-md-3 text-left"
                  v-if="dadosVisualizarUsuario.profile_id == 5"
                >
                  <label for="" class="mb-2 mt-4">Ano letivo</label> <br />
                  <input
                    type="text"
                    class="font-padrao"
                    v-model="dadosVisualizarUsuario.ano_letivo"
                    disabled
                  />
                </div>
              </div>
              <div v-if="dadosVisualizarUsuario.profile_id == 5">
                <div class="row mb-5">
                  <div class="h2 col-md-12">
                    <h2 class="text-left mt-5 pb-2 title-dados">
                      Dados do Responsável
                    </h2>
                  </div>
                  <div class="col-md-12 text-left">
                    <label for="nomeCompleto" class="mb-2 mt-4"
                      >Nome Completo</label
                    >
                    <br />
                    <input
                      type="text"
                      class="font-padrao"
                      placeholder="Nome do Responsável"
                      :value="dadosVisualizarUsuario.nome_responsavel"
                      id="nomeCompleto"
                      disabled
                    />
                  </div>
                  <div class="col-md-6 text-left">
                    <label for="" class="mb-2 mt-4">E-mail</label>
                    <br />
                    <input
                      type="email"
                      class="font-padrao"
                      placeholder="Digite@seuEmail.com"
                      :value="dadosVisualizarUsuario.email"
                      disabled
                    />
                  </div>
                  <div class="col-md-6 text-left">
                    <label for="telefoneResp" class="mb-2 mt-4">Telefone</label>
                    <input
                      type="text"
                      class="font-padrao"
                      placeholder="(99) 9 9999-9999"
                      v-mask="[' (##) # #####-####']"
                      :value="dadosVisualizarUsuario.telefone_responsavel"
                      id="telefoneResp"
                      disabled
                    />
                  </div>
                  <div
                  class="col-md-12 text-left"
                  v-if="dadosVisualizarUsuario.profile_id == 5"
                >
                  <label for="" class="mb-2 mt-4">Turma do aluno</label>
                  <ul :key="key" v-for="(turma, key) in dadosVisualizarUsuario.turmas">
                    <li >{{turma.cod}}</li>
                  </ul>
                </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mt-auto mb-auto">
              <div
                class="mb-3"
                v-if="dadosVisualizarUsuario.file || obj.file_id"
              >
                <img
                  id="fotoPerfilMeusDadosAluno"
                  :src="
                    dadosVisualizarUsuario.file
                      ? dadosVisualizarUsuario.file
                      : obj.file_id
                  "
                />
              </div>
              <div v-else>
                <img
                  id="fotoPerfilMeusDadosAluno"
                  src="../../../public/img/sem-foto.jpg"
                  alt="sem foto"
                />
                <span class="mt-5 d-flex j-c-center sem-imagem"
                  >Este usuário não possui foto de perfil</span
                >
              </div>
            </div>
          </div>

          <!---->
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import MeusDados from "../../views/MeusDados";

export default {
  data() {
    return {
      theDados: {},
      meusDados: this.$store.getters.getUserDatas,
      obj: {
        id: this.$store.getters.getUserDatas.id,
      },
      turmas: [],
    };
  },
  components: {
    MeusDados,
  },
  props: [
    "shadow_sm",
    "container",
    "bg_color",
    "targetDoing",
    "px",
    "dadosVisualizarUsuario",
  ],
  methods: {
    verifyToOpen() {
      this.theDados = this.dadosVisualizarUsuario;
    },
    verifyToClose() {
      this.theDados = {};
    },
  },
  computed: {
    ...mapGetters(["getUserDatas", "getUnidades"]),
  },
};
</script>

<style scoped>
#userId {
  z-index: 10;
  border: 100%;
  background-color: #42cc7e;
  border-radius: 20px;
  margin: 10px;
  width: 30px;
  height: 30px;
}

small {
  display: none;
}

#userId span {
  font-size: 12px;
}
#fotoPerfilMeusDadosAluno {
  background-color: #cbccd3;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  object-fit: cover;
  background-position: center;
}

.less-width input {
  width: 75%;
}
.teste {
  min-height: auto;
  margin-bottom: 10px;
}
input,
input::placeholder {
  font-size: 12px !important;
  color: #454c57 !important;
}
.here h2 {
  border-bottom: 1px solid #e4e5e8;
  font-size: 14px;
}
.teste {
  z-index: 5;
  position: relative;
  float: unset;
}
.sem-imagem {
  font-size: 12px;
}
</style>
